<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item :to="{ name: 'DashboardHome' }" >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>方案套件設定</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
        <h4 class="col-12 col-xl-2 mb-2 mb-xl-0 font-weight-bold">方案套件設定</h4>
      </div>
      <div class="d-flex mb-4 mb-xl-2 justify-content-end">
        <button
          class="btn btn-primary"
          @click="set"
        >
          設定
        </button>
      </div>

      <b-table
        striped
        hover
        responsive
        :items="suites"
        :fields="fields"
        :busy="isLoading"
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>

        <template #cell(checkbox)="data">
          <b-form-checkbox @change="clickCheckbox($event, data)"></b-form-checkbox>
        </template>
      </b-table>

      <div class="d-flex justify-content-center" style="margin-top: 80px">
        <b-pagination
          class="separated"
          v-model="currentPage"
          :total-rows="total"
          :per-page="perPage"
        ></b-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
// import { format } from "date-fns";
import suiteApi from "@/apis/suite";
import * as consts from "@/consts";
// import PermissionChecker from "@/utils/PermissionChecker";

export default {
  data() {
    return {
      consts,
      isLoading: false,
      suites: [],
      selectedSuiteIds: [],
      fields: [
        {
          key: 'checkbox',
          label: '',
        },
        {
          key: "code",
          label: "代碼",
        },
        {
          key: "name",
          label: "名稱",
        },
      ],
      perPage: 15,
      currentPage: 1,
      total: 0,
    };
  },
  mounted() {
    this.fetchSuites()
  },
  methods: {
    async fetchSuites(page = 1) {
      try {
        this.isLoading = true;

        const { data } = await suiteApi.list({
          page,
          per_page: this.perPage,
        });
        this.total = data.meta.total;
        this.perPage = data.meta.per_page;
        this.suites = data.data;
      } catch (error) {
        console.log("");
      }
      this.isLoading = false;
    },
    onRowSelected(rows) {
      console.log(rows);
    },
    clickCheckbox(event, data) {
      if (event) {
        this.selectedSuiteIds.push(data.item.id);
      } else {
        this.selectedSuiteIds = this.selectedSuiteIds.filter((id) => id !== data.item.id);
      }
    },
    async set() {
      try {
        this.isLoading = true;

        await suiteApi.set({
          suite_ids: this.selectedSuiteIds,
        });

        this.$swal.fire({
          icon: "success",
          title: "設定成功",
        });

        this.selectedSuiteIds = [];
      } catch (error) {
        console.log(error);
      }

      this.isLoading = false;
    }
  },
};
</script>
